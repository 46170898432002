<template>
  <!-- POST POST /fms_css/bill/finalBillRent 发送最终版本经营费用账单 -->
  <!-- POST /fms_css/bill/cost/create 新增账单费用调整 -->
  <!-- POST /fms_css/bill/cost/delete 删除账单费用调整 -->
  <!-- GET /fms_css/bill/cost/queryList 查询账单费用明细 -->
  <!-- POST /fms_css/bill/cost/uploadBillCostAdjust 上传账单费用调整excel(房租)-->
  <div>
    <div>
      <!-- 首要搜索框 -->
      <a-card style="margin-bottom: 24px">
        <a-row :gutter="0" style="line-height: 3">
          <div class="table-operator" style="margin-bottom: -8px">
            <a-form layout="inline">
              <a-row :gutter="5" style="line-height: 3.5">
                <!-- 账单日 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="账单日:">
                    <a-month-picker
                      :allowClear="false"
                      :disabledDate="disabledDate"
                      :value="queryParam.momentDate"
                      @change="searchMonth"
                      placeholder="选择月份"
                    />
                  </a-form-item>
                </a-col>
                <!-- 选择城市 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="选择城市:">
                    <a-select
                      style="width: 175px;margin-left: 0.5em;"
                      v-model="queryParam.cityId"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in userCities"
                        :key="item.id"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 门店编号 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="门店编号:">
                    <a-input
                      v-model="queryParam.facilityCode"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <!-- 门店名称 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="门店名称:">
                    <a-input
                      style="overflow: hidden; text-overflow: ellipsis"
                      v-model="queryParam.facilityName"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <!-- 发送状态 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="发送状态:">
                    <a-select
                      style="width: 180px"
                      v-model="queryParam.billStatus"
                      allowClear
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in statusTypeList"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-divider dashed></a-divider>
              <a-row>
                <a-col :md="18" :sm="24"> </a-col>
                <a-col :md="6" :sm="24">
                  <a-button
                    style="margin-left: 8px"
                    type="primary"
                    @click="sreachBill"
                    :disabled="dataLoading"
                    >搜索</a-button
                  >
                  <a-button style="margin-left: 8px" @click="resetSreach"
                    >重置</a-button
                  >
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-row>
      </a-card>
      <!-- 表格调整 -->
      <a-spin :spinning="dataLoading">
        <a-card style="margin-bottom: 24px" v-if="tableData.length > 0">
          <a-row :gutter="0" style="line-height: 3">
            <a-col :md="24" :sm="24" class="fr" v-if="!!isCanEdit">
              <a
                style="color:royalblue;"
                v-if="isCanEdit"
                href="https://css-2019-shanghai.oss-cn-shanghai.aliyuncs.com/rental-adjust-template-v2.xlsx"
                download="https://css-2019-shanghai.oss-cn-shanghai.aliyuncs.com/rental-adjust-template-v2.xlsx"
                >下载调整模版</a
              >
              <a-button
                style="margin-left: 1.5em"
                type="primary"
                @click="handleAddGenerateRental"
                v-if="isCanSendBill"
                >新增房租</a-button
              >
              <a-upload
                accept=".xlsx,.xls,.csv"
                withCredentials
                :action="this.uploadUrl()"
                name="multipartFile"
                @change="uploadCSV"
              >
                <a-button
                  style="margin-left: 1.5em"
                  type="primary"
                  v-if="isCanEdit"
                  >批量调整</a-button
                >
              </a-upload>
              <a-popconfirm
                placement="bottomRight"
                title="确认发送账单么？"
                @confirm="handleSendGroup"
                okText="Yes"
                cancelText="No"
              >
                <a-button
                  style="margin-left: 1.5em"
                  type="primary"
                  v-if="isCanSendBill"
                  >批量发送</a-button
                >
              </a-popconfirm>
              <a-popconfirm
                placement="bottomRight"
                title="确认发送账单么？"
                @confirm="sendBill"
                okText="Yes"
                cancelText="No"
              >
                <a-button
                  style="margin-left: 1.5em"
                  type="primary"
                  v-if="isCanSendBill"
                  >全部发送</a-button
                >
              </a-popconfirm>
            </a-col>
          </a-row>
          <a-divider dashed></a-divider>
          <!-- 账单显示表格列表 -->
          <a-row :gutter="0" style="line-height: 3">
            <a-table
              rowKey="code"
              :columns="columns"
              :dataSource="tableData"
              :pagination="false"
              :rowSelection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
                getCheckboxProps: getCheckboxProps
              }"
              :scroll="{ x: 1300 }"
            >
              <span slot="action" slot-scope="text, record" class="blueColor">
                <a
                  v-if="isCanViewDetail"
                  @click="viewRentDetail(record)"
                  class="m8"
                  >账单明细</a
                >
                <a
                  v-if="record.billStatus == 1 && isCanEdit"
                  class="m8"
                  @click="handleRentEdit(record)"
                  >调整</a
                >
                <a-popconfirm
                  placement="bottomRight"
                  title="确认发送账单么？"
                  @confirm="handleSendItem(record)"
                  okText="Yes"
                  cancelText="No"
                >
                  <a
                    type="primary"
                    v-if="record.billStatus == 1 && isCanSendBill"
                    class="m8"
                    >发送账单</a
                  >
                </a-popconfirm>
                <!-- confirmStatus 删除条件 未确认订单 -->
                <a-popconfirm
                  placement="bottomRight"
                  title="确认删除账单么？"
                  @confirm="handleDeleteItem(record)"
                  okText="Yes"
                  cancelText="No"
                >
                  <a
                    type="primary"
                    v-if="record.billStatus == 1 && isCanSendBill"
                    class="m8"
                    >删除</a
                  >
                </a-popconfirm>
                <!-- confirmStatus 撤回条件 已经发送 并且 没有确认 -->
                <a-popconfirm
                  placement="bottomRight"
                  title="确认撤回账单么？"
                  @confirm="handleRevokeItem(record)"
                  okText="Yes"
                  cancelText="No"
                >
                  <a
                    type="primary"
                    class="m8"
                    v-if="
                      record.billStatus != 1 &&
                        record.confirmStatus == 1 &&
                        isCanSendBill
                    "
                    >撤回</a
                  >
                </a-popconfirm>
              </span>
            </a-table>
          </a-row>
        </a-card>
      </a-spin>
      <!-- 账单明细卡片 -->
      <a-modal
        title="查看账单明细"
        :width="1200"
        :visible="isViewRentDetail"
        destroyOnClose
        :closable="false"
      >
        <template slot="footer">
          <a-button
            :loading="isViewRentCloseLoading"
            @click.stop="handleCancelViewRentDetail"
            >关闭</a-button
          >
        </template>
        <a-spin :spinning="isViewRentDetailLoading">
          <div class="pad10">
            <a-row :gutter="0">
              <h3>费用明细</h3>
              <a-table
                rowKey="code"
                :columns="rentColumns"
                :dataSource="rentTableData"
                :pagination="false"
              >
              </a-table>
            </a-row>
            <a-divider dashed></a-divider>
            <a-row :gutter="0">
              <h3>费用调整</h3>
              <a-table
                rowKey="code"
                :columns="rentEventColumns"
                :dataSource="rentEventTableData"
                :pagination="false"
              >
                <span slot="action" slot-scope="text, record" class="blueColor">
                  <a-popconfirm
                    title="删除这个房租减免么?"
                    @confirm="deteleRentEvent(record)"
                    okText="Yes"
                    cancelText="No"
                  >
                    <a
                      href="javascript:;"
                      v-if="record.f_billStatus === 1 && isCanEdit"
                      >删除</a
                    >
                  </a-popconfirm>
                </span>
              </a-table>
            </a-row>
            <a-divider dashed></a-divider>
            <a-row :gutter="0" class="fr">
              <p>
                账单总金额：<span style="margin-right:8px;">{{
                  rentTableTotal
                }}</span>
              </p>
            </a-row>
          </div>
        </a-spin>
      </a-modal>
      <!-- 调整卡片 -->
      <a-modal
        title="调整"
        :visible="isEditRentEvent"
        :confirmLoading="isEditRentEventLoading"
        @ok="handleSaveRentEdit"
        @cancel="handleCancelRentEdit"
        destroyOnClose
      >
        <a-form layout="inline">
          <a-row :gutter="5" style="line-height: 3.5">
            <a-col :md="26" :sm="24">
              <a-form-item label="调整日期:">
                <a-range-picker
                  style="width: 300px"
                  class="nob"
                  @change="selectRentEventRange"
                />
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24">
              <a-form-item label="费用类型">
                <a-select v-model="editRentEventObj.type" style="width: 300px" placeholder="请选择">
                  <a-select-option v-for="item in costTypeList" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24">
              <a-form-item label="调整方式">
                <a-select v-model="editRentEventObj.handleType" style="width: 300px" placeholder="请选择">
                  <a-select-option v-for="item in handleTypeList" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24">
              <a-form-item label="调整金额:">
                <a-input
                  v-model="editRentEventObj.totalAmount"
                  style="width: 300px"
                  class="nob"
                  placeholder="请输入"
                />
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24">
              <a-form-item label="调整原因:">
                <a-textarea
                  v-model="editRentEventObj.remark"
                  placeholder="请输入"
                  class="nob"
                  style="width: 300px"
                  :rows="4"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-modal>
      <!-- 增加房租账单条件 -->
      <a-modal
        title="生成房租"
        :visible="isShowGenerateRental"
        :confirmLoading="generateRentalLoading"
        @ok="handleSaveAddGenerateRental"
        @cancel="handleCancelGenerateRental"
        destroyOnClose
      >
        <a-form layout="inline">
          <a-row :gutter="5" style="line-height: 3.5">
            <a-col :md="24" :sm="24">
              <a-form-item label="选择门店:">
                <a-select
                  v-model="generateRentalObj.facilityId"
                  placeholder="请选择"
                  style="width: 300px"
                  @change="searchStoreList"
                >
                  <a-select-option
                    v-for="item in brandNameList"
                    :key="item.id"
                    >{{ item.optionText }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24">
              <a-form-item label="选择档口:">
                <a-select
                  v-model="generateRentalObj.kitchenId"
                  placeholder="请选择"
                  style="width: 300px"
                  @change="searchContractList"
                >
                  <a-select-option v-for="item in storeList" :key="item.id">{{
                    item.code
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24">
              <a-form-item label="选择合同:">
                <a-select
                  v-model="generateRentalObj.contractCode"
                  placeholder="请选择"
                  @change="changeContract"
                  style="width: 300px"
                >
                  <a-select-option
                    v-for="item in contractList"
                    :key="item.id"
                    >{{ item.contractCode }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24">
              <a-form-item label="店铺名称:">
                <a-input
                  v-model="generateRentalObj.storeName"
                  style="width: 300px"
                  placeholder="请输入"
                  disabled
                />
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24">
              <a-form-item label="基础服务费:">
                <a-input
                  v-model="generateRentalObj.lessorActualRent"
                  style="width: 300px"
                  placeholder="请输入"
                  disabled
                />
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24">
              <a-form-item label="场地使用费:">
                <a-input
                    v-model="generateRentalObj.siteUseServiceFee"
                    style="width: 300px"
                    placeholder="请输入"
                    disabled
                />
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24">
              <a-form-item label="租金付款周期:">
                <a-input
                  v-model="generateRentalObj.rentPaymentMonth"
                  style="width: 300px"
                  placeholder="请输入"
                  disabled
                />
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24">
              <a-form-item label="房租月份:">
                <a-month-picker style="width: 300px" @change="selectRentalMonth" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>
<script>
  
import moment from "moment";

import { mapActions, mapGetters } from "vuex";
import { GET_USER_CITIES } from "@/store/modules/actions.type";
import { hasPermissions } from "../../utils/auth";
import { FacilityActions } from "../../utils/actions";
export default {
  name: "financeRent",
  data() {
    return {
      // 搜索
      queryParam: {
        momentDate: null
      },
      exQueryParam: {
        momentDate: null
      },
      // loading
      dataLoading: false,
      tableData: [],
      // 数字展示
      columns: [
        {
          title: "账单ID",
          dataIndex: "id",
          key: "id",
          width: 200,
        },
        {
          title: "门店名称",
          dataIndex: "facilityName",
          key: "facilityName",
          width: 200,
        },
        {
          title: "店铺编号",
          width: 100,
          dataIndex: "facilityCode",
          key: "facilityCode"
        },
        {
          title: "档口编号",
          width: 100,
          dataIndex: "kitchenName",
          key: "kitchenName"
        },
        {
          title: "店铺Id",
          width: 100,
          dataIndex: "storeId",
          key: "storeId"
        },
        {
          title: "店铺名称",
          width: 200,
          dataIndex: "storeName",
          key: "storeName"
        },
        {
          title: "账单日期",
          width: 120,
          dataIndex: "billDate",
          key: "billDate"
        },
        {
          title: "账单周期",
          width: 220,
          customRender: (text, row, index) => {
            if (row.cycleStartDateLg) {
              return (
                <span>
                  {moment(row.cycleStartDateLg).format("YYYY-MM-DD")}~
                  {moment(row.cycleEndDateLg).format("YYYY-MM-DD")}
                </span>
              );
            } else {
              return "";
            }
          }
        },
        {
          title: "账单总金额",
          width: 120,
          dataIndex: "billAmount",
          key: "billAmount"
        },
        {
          title: "账单状态",
          width: 100,
          customRender: (text, row, index) => {
            if (row.billStatus * 1 == 1) {
              return <span>未发送</span>;
            } else if (row.billStatus * 1 == 2) {
              return <span>已发送</span>;
            }
          }
        },
        {
          title: "商户确认状态",
          width: 120,
          dataIndex: "confirmStatusName",
          key: "confirmStatusName"
        },
        {
          title: "操作",
          key: "action",
          width: 280,
          fixed: 'right',
          scopedSlots: { customRender: "action" }
        }
      ],
      //调整类型
      handleTypeList: [
        { id: 1, name: "减免" },
        { id: 2, name: "扣除" },
      ],
      //费用类型
      costTypeList: [
        { id: 4, name: "基础服务费" },
        { id: 65, name: "场地使用服务费" },
      ],
      // 房租详情弹窗isViewRentDetail
      isViewRentDetailLoading: false,
      isViewRentCloseLoading: false,
      isViewRentDetail: false,
      // 房租详情 -- 房租表格
      rentColumns: [
        {
          title: "周期",
          customRender: (text, row, index) => {
            if (row.cycleStartTimeLg) {
              return (
                <span>
                  {moment(row.cycleStartTimeLg).format("YYYY-MM-DD")}~
                  {moment(row.cycleEndTimeLg).format("YYYY-MM-DD")}
                </span>
              );
            } else {
              return "";
            }
          }
        },
        {
          title: "费用类型",
          dataIndex: "typeName",
          key: "typeName"
        },
        {
          title: "费用金额",
          dataIndex: "totalAmount",
          key: "totalAmount"
        }
      ],
      rentTableData: [],
      // 房租详情 -- 房租减免表格
      rentEventColumns: [
        {
          title: "周期",
          customRender: (text, row, index) => {
            if (row.cycleStartTimeLg) {
              return (
                <span>
                  {moment(row.cycleStartTimeLg).format("YYYY-MM-DD")}~
                  {moment(row.cycleEndTimeLg).format("YYYY-MM-DD")}
                </span>
              );
            } else {
              return "";
            }
          }
        },
        {
          title: "费用类型",
          dataIndex: "typeName",
          key: "typeName"
        },
        {
          title: "调整方式",
          dataIndex: "handleTypeName",
          key: "handleTypeName"
        },
        {
          title: "调整金额",
          dataIndex: "totalAmount",
          key: "totalAmount"
        },
        {
          title: "调整原因",
          dataIndex: "remark",
          key: "remark"
        },
        {
          title: "操作时间",
          customRender: (text, row, index) => {
            if (row.createTimeLg) {
              return (
                <span>{moment(row.createTimeLg).format("YYYY-MM-DD")}</span>
              );
            } else {
              return "";
            }
          }
        },
        {
          title: "操作",
          key: "action",
          width: "200px",
          scopedSlots: { customRender: "action" }
        }
      ],
      rentEventTableData: [],
      queryBillInfo: {},
      rentTableTotal: 0,
      // 编辑房租事件
      isEditRentEventLoading: false,
      isEditRentEvent: false,
      editRentEventObj: {},
      // 表格选择
      selectedRowKeys: [],
      selectedRows: [],
      // 状态列表
      statusTypeList: [
        { id: 1, name: "未发送" },
        { id: 2, name: "已发送" }
      ],
      // 新增房租弹窗
      isShowGenerateRental: false,
      generateRentalLoading: false,
      // 门店列表
      brandNameList: [],
      // 档口列表
      storeList: [],
      // 合同列表
      contractList: [],
      generateRentalObj: {},
    };
  },
  methods: {
    handleDeleteItem(record) {
      axios({
        url:   this.$baseUrl + "bill/deleteRentBill",
        method: "GET",
        params: {
          billId: record.id
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.$message.info(`操作成功！`);
          this.sreachBill();
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    handleRevokeItem(record) {
      axios({
        url:   this.$baseUrl + "bill/cancelRentBill",
        method: "GET",
        params: {
          billId: record.id
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.$message.info(`操作成功！`);
          this.sreachBill();
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    handleAddGenerateRental() {
      this.generateRentalObj = {};
      this.generateRentalLoading = true;
      this.brandNameList = [];
      this.storeList = [];
      this.contractList = [];
      if (!this.queryParam.cityId) {
        this.$message.error("请先选择城市！");
        this.generateRentalLoading = false;
        this.isShowGenerateRental = false;
        return;
      }
      axios({
        url:   this.$baseUrl + "facility/querySelectByBrandAndCity",
        method: "GET",
        params: {
          cityId: this.queryParam.cityId
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.isShowGenerateRental = true;
          this.generateRentalObj.cityId = this.queryParam.cityId;
          this.brandNameList = res.data.rows;
          this.generateRentalLoading = false;
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    searchStoreList(val) {
      axios({
        url:   this.$baseUrl + "kitchen/querySelect?",
        method: "GET",
        params: {
          facilityId: val
        }
      }).then(res => {
        this.storeList = res.data.rows || [];
        this.generateRentalObj.kitchenId = "";
        this.generateRentalObj.storeName = "";
        this.generateRentalObj.lessorActualRent = "";
        this.generateRentalObj.rentPaymentMonth = "";
      });
    },
    // 依据档口 选择获取相关合同列表
    searchContractList(val) {
      this.contractList = [];
      axios({
        url:   this.$baseUrl + "contract/listByFacilityIdAndKitchenId",
        method: "GET",
        params: {
          facilityId: this.generateRentalObj.facilityId,
          kitchenId: val
        }
      }).then(res => {
        this.generateRentalObj.storeName = "";
        this.generateRentalObj.lessorActualRent = "";
        this.generateRentalObj.siteUseServiceFee = "";
        this.generateRentalObj.rentPaymentMonth = "";
        if (res.data.rows) {
          this.contractList = res.data.obj || [];
        }
      });
    },
    // 选择合同 获取不同字段
    changeContract(val) {
      let contract = (this.contractList.filter(item => item.id == val) || [
        {}
      ])[0];
      this.generateRentalObj.storeName = contract.storeName || "";
      this.generateRentalObj.lessorActualRent = contract.lessorActualRent || "";
      this.generateRentalObj.siteUseServiceFee = contract.siteUseServiceFee || "";
      this.generateRentalObj.rentPaymentMonth = contract.rentPaymentMonth || "";
    },
    // 选择房租月份
    selectRentalMonth(month, monthString) {
      this.generateRentalObj.billingPeriod = monthString;
      this.generateRentalObj.momentDate = moment(monthString);
    },
    // 验证房租数据
    verifyInfo(obj) {
      if (!obj.facilityId) {
        this.$message.error(`请选择门店！`);
        return false;
      }
      if (!obj.kitchenId) {
        this.$message.error(`请选择档口！`);
        return false;
      }
      if (!obj.contractCode) {
        this.$message.error(`选择合同！`);
        return false;
      }
      if (!obj.storeName) {
        this.$message.error(`店铺名称有误！`);
        return false;
      }
      if (!obj.lessorActualRent) {
        this.$message.error(`基础服务费有误！`);
        return false;
      }
      if (!obj.rentPaymentMonth) {
        this.$message.error(`租金付款周期有误！`);
        return false;
      }
      if (!obj.billingPeriod) {
        this.$message.error(`首期房租开始时间有误！`);
        return false;
      }
      return true;
    },
    handleSaveAddGenerateRental() {
      if (!this.verifyInfo(this.generateRentalObj)) {
        return;
      }
      axios({
        url:   this.$baseUrl + "bill/generateRentalBill",
        method: "GET",
        params: {
          contractId: this.generateRentalObj.contractCode,
          billingPeriod: this.generateRentalObj.billingPeriod
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          (this.isShowGenerateRental = false), (this.generateRentalLoading = false);
          this.$message.info(`操作成功！`);
          this.sreachBill();
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    handleCancelGenerateRental() {
      this.generateRentalObj = {};
      (this.isShowGenerateRental = false), (this.generateRentalLoading = false);
    },
    ...mapActions("cities", [GET_USER_CITIES]),
    moment,
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRows = selectedRows;
      this.selectedRowKeys = selectedRowKeys;
    },
    // 不可选择
    getCheckboxProps(record) {
      return {
        props: {
          disabled: record.billStatus * 1 == 2,
          name: record.name
        }
      };
    },
    //
    uploadUrl() {
      return   this.$baseUrl + "upload/ossFile";
    },
    // 上传表格
    uploadCSV({ fileList }) {
      this.dataLoading = true;
      if (fileList.length >= 1) {
        fileList.forEach((element, index) => {
          if (element.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
          if (element.response) {
            if (element.response.returnCode == "200") {
              this.afterUploadCSV(element);
              fileList.splice(index, 1);
            } else {
              this.$message.error("上传失败！");
              this.dataLoading = false;
              fileList.splice(index, 1);
              return;
            }
          }
        });
      }
    },
    // afterUploadCSV
    afterUploadCSV(info) {
      let data = Object.assign({}, this.exQueryParam);
      data.attachmentName = info.name;
      data.url = info.response.obj;
      data.billType = 4;
      let facilityIdList = [];
      this.tableData.forEach((item, index) => {
        facilityIdList.push(item.facilityId);
      });
      data.facilityIdList = facilityIdList;
      // POST /fms_css/bill/cost/uploadBillCostAdjust 上传账单费用调整excel(房租)
      axios({
        url:   this.$baseUrl + "bill/cost/uploadBillCostAdjust",
        method: "POST",
        data: data
      })
        .then(res => {
          if (res.data.success) {
            this.sreachBill();
          } else {
            this.dataLoading = false;
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    // 搜索
    sreachBill() {
      if (!this.queryParam.billDate) {
        this.$message.error(`账单日期必须选择！`);
        return;
      }
      if (!this.queryParam.cityId) {
        this.$message.error(`城市必须选择！`);
        return;
      }
      this.dataLoading = true;
      this.tableData = [];
      axios({
        url:   this.$baseUrl + "bill/queryBillRent",
        method: "POST",
        data: {
          ...this.queryParam
        }
      })
        .then(res => {
          if (res.data.rows) {
            this.tableData = res.data.rows;
            // console.log(res.data.rows)
            // 搜索状态留存
            this.exQueryParam = Object.assign({}, this.queryParam);
          } else {
            this.$message.error(res.data.returnMsg);
          }
          this.dataLoading = false;
        })
        .catch(error => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    // 重置页面
    resetSreach() {
      this.queryParam = { momentDate: "" };
      this.exQueryParam = { momentDate: "" };
      this.tableData = [];
    },
    // 时间禁用
    disabledDate(current) {
      // Can not select days before today and today
      return (
        current &&
        current >=
          moment()
            .month(moment().month() + 1)
            .endOf("day")
      );
    },
    // 搜索函数
    // 月份搜索函数
    searchMonth(month, monthString) {
      this.queryParam.billDate = monthString;
      this.queryParam.momentDate = moment(monthString);
    },
    // 查看账单明细弹窗
    viewRentDetail(info) {
      this.rentTableData = [];
      this.rentEventTableData = [];
      this.rentTableTotal = 0;
      this.queryBillInfo = info;
      this.isViewRentDetail = true;
      this.isViewRentDetailLoading = true;
      // GET /fms_css/bill/cost/queryList 查询账单费用明细
      axios({
        url:   this.$baseUrl + "/bill/cost/queryList",
        method: "GET",
        params: {
          billId: info.id
        }
      })
        .then(res => {
          if (res.data.rows && res.data.success) {
            // 数据绑定
            // 调整部分
            let tableData1 = [];
            // 房租部分
            let tableData2 = [];
            let total = 0;
            res.data.rows.forEach((item, index) => {
              // 添加账单状态
              item.f_billStatus = info.billStatus;
              if (item.handleType === 1){
                item.handleTypeName = "减免";
              } else if (item.handleType === 2){
                item.handleTypeName = "扣除";
              }
              if (item.isAdjust === "Y" || item.handleType === 1) {
                tableData1.push(item);
                total = total - item.totalAmount * 1;
              } else {
                tableData2.push(item);
                total = total + item.totalAmount * 1;
              }
            });
            this.rentTableData = tableData2;
            this.rentEventTableData = tableData1;
            this.rentTableTotal = total.toFixed(2);
            this.isViewRentDetailLoading = false;
          } else {
            this.$message.error(res.data.returnMsg);
            setTimeout(() => {
              this.isViewRentDetailLoading = false;
              this.isViewRentDetail = false;
            }, 600);
          }
        })
        .catch(error => {
          this.$message.error(error.message);
          setTimeout(() => {
            this.isViewRentDetailLoading = false;
            this.isViewRentDetail = false;
          }, 600);
        });
    },
    // handleCancelViewRentDetail
    handleCancelViewRentDetail() {
      this.isViewRentDetail = false;
    },
    // handleRentEdit 调整账单状态
    handleRentEdit(info) {
      this.isEditRentEvent = true;
      this.editRentEventObj = { billId: info.id };
    },
    // 选择减免周期 --  名字待更换
    selectRentEventRange(date, dateString) {
      this.editRentEventObj.timeRange = dateString;
      // this.editRentEventObj.timeRange = date
    },
    handleSaveRentEdit() {
      // this.isEditRentEvent = false;
      // POST /fms_css/bill/cost/create 查询房租账单调整
      if (!this.editRentEventObj.timeRange) {
        this.$message.error("调整日期必须填写！");
        return;
      }
      if (
        !this.editRentEventObj.totalAmount ||
        isNaN(this.editRentEventObj.totalAmount)
      ) {
        this.$message.error("调整金额有误！");
        return;
      }
      if (!this.editRentEventObj.remark) {
        this.$message.error("调整原因必须填写！");
        return;
      }
      if (!this.editRentEventObj.handleType) {
        this.$message.error("调整类型必须填写！");
        return;
      }
      if (!this.editRentEventObj.type) {
        this.$message.error("费用类型必须填写！");
        return;
      }
      this.editRentEventObj.cycleStartTime = moment(
        this.editRentEventObj.timeRange[0]
      );
      this.editRentEventObj.cycleEndTime = moment(
        this.editRentEventObj.timeRange[1]
      );
      this.isEditRentEventLoading = true;
      console.log(this.editRentEventObj);
      axios({
        url:   this.$baseUrl + "/bill/cost/create",
        method: "POST",
        data: {
          ...this.editRentEventObj
        }
      })
        .then(res => {
          if (res.data.rows && res.data.success) {
            // 搜索状态归还
            this.queryParam = Object.assign({}, this.exQueryParam);
            // 刷新页面
            this.sreachBill();
          } else {
            this.$message.error(res.data.returnMsg);
          }
          this.isEditRentEventLoading = false;
          this.isEditRentEvent = false;
        })
        .catch(error => {
          this.isEditRentEventLoading = false;
          this.$message.error(error.message);
        });
    },
    handleCancelRentEdit() {
      this.isEditRentEvent = false;
    },
    // deteleRentEvent 删除房租变化 操作 删除没法同步css 目前先暂停 后续直接使用line item id 在删除
    deteleRentEvent(info) {
      this.isViewRentDetailLoading = true;
      this.isViewRentCloseLoading = true;
      // POST /fms_css/bill/cost/delete 删除账单费用调整
      axios({
        url:   this.$baseUrl + "/bill/cost/delete",
        method: "GET",
        params: {
          billCostId: info.id
        }
      })
        .then(res => {
          if (res.data.rows && res.data.success) {
            // 数据绑定
            // this.isViewRentDetail = false;
            // this.isViewRentDetail = true;
            this.viewRentDetail(this.queryBillInfo);
            // 搜索状态归还
            this.queryParam = Object.assign({}, this.exQueryParam);
            // 刷新页面
            this.sreachBill();
          } else {
            this.$message.error(res.data.returnMsg);
          }
          this.isViewRentDetailLoading = false;
          this.isViewRentCloseLoading = false;
        })
        .catch(error => {
          this.$message.error(error.message);
          this.isViewRentDetailLoading = false;
          this.isViewRentCloseLoading = false;
        });
    },
    // handleSend
    handleSend(val) {
      this.dataLoading = true;
      this.queryParam = Object.assign({}, this.exQueryParam);
      // POST /fms_css/bill/finalBillRent 发送最终版本经营费用账单
      axios({
        url:   this.$baseUrl + "/bill/finalBillRent",
        method: "POST",
        data: {
          billDate: val.bdate,
          billIdList: val.ids,
          facilityIdList: val.fIds
        }
      })
        .then(res => {
          if (res.data.returnCode == "200") {
            // 搜索状态留存
            this.$message.info(res.data.returnMsg);
            this.selectedRowKeys = [];
            this.selectedRows = [];
            this.sreachBill();
          } else {
            this.$message.error(res.data.returnMsg);
          }
          this.dataLoading = false;
        })
        .catch(error => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    // 全部发送账单
    sendBill() {
      let val = {};
      val.bdate = this.exQueryParam.billDate;
      if (this.tableData.length <= 0) {
        this.$message.error(`没有可以发送的账单！`);
        return;
      }
      // 收集ID
      let ids = [];
      let fIds = [];
      this.tableData.forEach((item, index) => {
        // 字段
        if (item.billStatus == 1) {
          ids.push(item.id);
          fIds.push(item.facilityId);
        }
      });
      if (ids.length <= 0) {
        this.$message.error(`没有可以发送的账单！`);
        return;
      } else {
        val.ids = ids;
        val.fIds = fIds;
        this.handleSend(val);
      }
    },
    // 单独发送账单
    handleSendItem(record) {
      let val = {};
      let ids = [];
      let fIds = [];
      val.bdate = this.exQueryParam.billDate;
      if (record.billStatus == 1) {
        ids.push(record.id);
        fIds.push(record.facilityId);
      }
      if (ids.length <= 0) {
        this.$message.error(`不可发送的账单！`);
        return;
      } else {
        val.ids = ids;
        val.fIds = fIds;
        this.handleSend(val);
      }
    },
    // 批量发送
    handleSendGroup() {
      let val = {};
      val.bdate = this.exQueryParam.billDate;
      if (this.selectedRows.length <= 0) {
        this.$message.error(`没有可以发送的账单！`);
        return;
      }
      // 收集ID
      let ids = [];
      let fIds = [];
      this.selectedRows.forEach((item, index) => {
        // 字段
        if (item.billStatus == 1) {
          ids.push(item.id);
          fIds.push(item.facilityId);
        }
      });
      if (ids.length <= 0) {
        this.$message.error(`没有可以发送的账单！`);
        return;
      } else {
        val.ids = ids;
        val.fIds = fIds;
        this.handleSend(val);
      }
    }
  },
  created() {
    // 初始化城市列表
    this.getUserCities();
  },
  computed: {
    ...mapGetters("cities", ["userCities"]),
    ...mapGetters("users", ["authenticatedUser"]),
    isCanSendBill() {
      return hasPermissions(
        [FacilityActions.RENT_BILL_SEND],
        this.authenticatedUser.actions
      );
    },
    isCanEdit() {
      return hasPermissions(
        [FacilityActions.RENT_BILL_ADJUST],
        this.authenticatedUser.actions
      );
    },
    isCanViewDetail() {
      return hasPermissions(
        [FacilityActions.RENT_BILL_READ],
        this.authenticatedUser.actions
      );
    },
    isCanAddEdit() {
      return false;
    }
  }
};
</script>
<style lang="less">
.m8 {
  display: inline-block;
  margin: 8px;
}
.fr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.nob {
  border-radius: 0 !important;
}
.pad10 {
  box-sizing: border-box;
  padding: 10px 0 10px 10px;
  h3 {
    margin-bottom: 10px;
  }
}
.upfile {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 3;
  > a {
    color: rgb(85, 163, 200);
    text-decoration: underline;
  }
  p {
    color: rgb(85, 163, 200);
    margin-left: 1.2em;
    margin-bottom: 0;
    line-height: 3;
    text-decoration: underline;
  }
  span {
    margin-left: 1.5em;
    cursor: pointer;
    font-size: 1.5em;
    line-height: 1.5em;
  }
}
</style>
